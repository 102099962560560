import { Grid, Stack, Typography } from '@mui/material';
import CustomButton from 'components/atoms/Button';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Search from 'components/icons/Search';
import { StyledBox } from './styles';
import OrderCardDetail, {
  OrderActionsAdmins,
} from 'components/molecules/CardContent';
import { useLazyGetOrdersQuery } from 'api/orderForm';
import useCustomPagination from 'hooks/useCustomPagination';
import CustomPagination from 'components/molecules/CustomPagination';
import theme from 'theme';
import LoaderSkelton from 'components/atoms/LoaderSkelton';
import SearchBox from 'components/atoms/SearchBox';
import {
  useCancelOrderMutation,
  useSendVetSignatureReminderMutation,
} from 'api/dashboard';
import BackdropLoader from 'components/atoms/BackdropLoader';
import useToast from 'hooks/useToast';
import { useCustomNavigationLogic } from 'hooks/useCustomNavigationLogic';
import { useLocation, useParams } from 'react-router-dom';
import { getUserRole } from 'store/features/auth/index.selector';
import {
  PAGES,
  defaultDashboardPageSize,
  DASHBOARD_INITIAL_PAGE,
  ORDER_STATUSES,
} from 'constants/index';
import { useSelector } from 'react-redux';
import RenderByCondition from 'components/atoms/RenderByCondition';
import { formatDate, toSpaceSeparatedNumber } from 'utils';

const AdminOrders = () => {
  const userRole = useSelector(getUserRole);
  const { getParamsValues, paramsValues, setParams }: any =
    useCustomNavigationLogic({
      queryParams: ['searchKey', 'page', 'pageSize'],
      originLocation: PAGES[userRole].DASHBOARD,
    });
  const lc = useLocation();
  const routerData = useParams();

  const [refetch, { data, isFetching, isLoading }] = useLazyGetOrdersQuery();
  const { currentPage, handleChange, defaultPage, totalPages } =
    useCustomPagination({ initialPage: 1, totalPages: data?.totalPages });
  const [inputValue, setInputValue] = useState('');
  const { t } = useTranslation();
  const { toast } = useToast();

  useEffect(() => {
    getParamsValues(lc?.pathname);
  }, [lc, routerData]);

  useEffect(() => {
    setInputValue(paramsValues?.searchKey || '');
    const pageNumber = parseInt(paramsValues?.page) || DASHBOARD_INITIAL_PAGE;

    handleChange({} as any, pageNumber || DASHBOARD_INITIAL_PAGE);

    refetch({
      pageNumber: pageNumber || DASHBOARD_INITIAL_PAGE,
      searchKey: paramsValues?.searchKey || '',
      pageSize: parseInt(paramsValues?.pageSize) || defaultDashboardPageSize,
    });
  }, [paramsValues, refetch]);

  const handleSearchChange = (event) => {
    const value = event?.target?.value;

    setInputValue(value);

    if (value === '') {
      setParams({
        searchKey: value,
        page: DASHBOARD_INITIAL_PAGE,
        pageSize: defaultDashboardPageSize,
      });
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setParams({
        searchKey: inputValue,
        page: DASHBOARD_INITIAL_PAGE,
        pageSize: defaultDashboardPageSize,
      });
      refetch({ pageNumber: 1, searchKey: inputValue });
    }
  };

  const onSearchClick = () => {
    setParams({
      searchKey: inputValue,
      page: DASHBOARD_INITIAL_PAGE,
      pageSize: defaultDashboardPageSize,
    });
    refetch({ pageNumber: DASHBOARD_INITIAL_PAGE, searchKey: inputValue });
  };

  const paginationOnClick = (event, value) => {
    handleChange(event, value);
    setParams({
      searchKey: inputValue || '',
      page: value,
      pageSize: defaultDashboardPageSize,
    });
    refetch({
      pageNumber: value,
      searchKey: inputValue || '',
      pageSize: defaultDashboardPageSize,
    });
  };

  const [
    cancelOrder,
    {
      isSuccess: cancelOrderSuccess,
      isError: cancelOrderIsError,
      isLoading: cancelOrderIsLoading,
    },
  ] = useCancelOrderMutation();

  useEffect(() => {
    if (cancelOrderSuccess) {
      toast.success(t('messages.orderCancelSuccess'), {});
    } else if (cancelOrderIsError) {
      toast.error(t('messages.orderCancelFail'), {});
    }
  }, [cancelOrderSuccess, cancelOrderIsError]);

  const [
    sendVetSignatureReminder,
    {
      isSuccess: sendVetSignatureReminderSuccess,
      isError: sendVetSignatureReminderIsError,
      isLoading: sendVetSignatureReminderIsLoading,
    },
  ] = useSendVetSignatureReminderMutation();

  useEffect(() => {
    if (sendVetSignatureReminderSuccess) {
      toast.success(t('messages.messageFollowUpConfirmation'), {});
    } else if (sendVetSignatureReminderIsError) {
      toast.error(t('messages.vetSignatureReminderFail'), {});
    }
  }, [sendVetSignatureReminderSuccess, sendVetSignatureReminderIsError]);

  const orders: any = data?.items;
  const orderSerialStepStatuses = data?.orderSerialStepStatuses;
  return (
    <Grid container direction="column">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        spacing={1}
        marginY={4}
      >
        <Grid item xs>
          <SearchBox
            size="medium"
            placeholder={t('global.searchPlaceHolder')}
            startIcon={<Search />}
            onChange={handleSearchChange}
            onKeyDown={handleKeyPress}
            value={inputValue}
          />
        </Grid>
        <Grid item>
          <CustomButton
            variant="outlined"
            size="medium"
            onClick={onSearchClick}
          >
            {t('global.search')}
          </CustomButton>
        </Grid>
      </Grid>
      <Grid item>
        <RenderByCondition show={[Boolean(isFetching || isLoading)]}>
          <Stack spacing={2} width="100%">
            <LoaderSkelton />
            <br />
            <LoaderSkelton />
          </Stack>
        </RenderByCondition>
        <RenderByCondition show={[orders?.length === 0]}>
          <StyledBox>
            <Typography
              variant="body1"
              color={theme.palette.primary[800]}
              sx={{ my: 1 }}
            >
              {t('errorMessages.notFoundSearch')}
            </Typography>
          </StyledBox>
        </RenderByCondition>
        <RenderByCondition show={[orders?.length > 0, !isFetching]}>
          {orders?.map((order: any) => {
            const formattedSubmissionDate = order?.creationDate
              ? formatDate(order?.creationDate)
              : null;

            const formattedConfirmationDate = order?.confirmationDate
              ? formatDate(order?.confirmationDate)
              : null;

            const formattedShippingDate = order?.shippingDate
              ? formatDate(order?.shippingDate)
              : null;

            const numberOfDoses = toSpaceSeparatedNumber(
              order?.numberOfDoses
            ).toLocaleString();

            const orderSerials = order?.orderSerials
              ?.map((orderSerial) => {
                let lastNonNullStepIndex = -1; // Index of the last step with a non-null stepDate

                orderSerial?.orderSerialSteps?.forEach((serialStep, index) => {
                  if (serialStep?.stepDate !== null) {
                    lastNonNullStepIndex = index;
                  }
                });

                return {
                  ...orderSerial,
                  serialNumber: orderSerial?.serialNumber,
                  steps: orderSerial?.orderSerialSteps?.map(
                    (serialStep, index) => {
                      const stepData = orderSerialStepStatuses?.find(
                        (item) =>
                          item?.id === serialStep?.orderSerialStepStatusId
                      );

                      const formattedDate = serialStep?.stepDate
                        ? formatDate(serialStep.stepDate)
                        : null;

                      return {
                        ...serialStep,
                        name: stepData?.name,
                        date: formattedDate,
                        description: stepData?.description,
                        color: stepData?.color,
                        current: index === lastNonNullStepIndex,
                      };
                    }
                  ),
                };
              })
              .filter((item) => item);
            return (
              <OrderCardDetail
                data={order}
                key={order?.id}
                orderSerialStepStatuses={orderSerialStepStatuses}
                orderSerials={orderSerials}
                formattedSubmissionDate={formattedSubmissionDate}
                formattedConfirmationDate={formattedConfirmationDate}
                formattedShippingDate={formattedShippingDate}
                numberOfDoses={numberOfDoses}
                actionButtons={
                  <OrderActionsAdmins
                    orderId={order?.id}
                    orderStatus={order?.orderStatus?.name}
                    customerId={order?.customer?.id}
                    actionsDisabled={
                      order?.orderStatus?.name?.toLowerCase?.() ===
                      ORDER_STATUSES.CANCELLED?.toLowerCase?.()
                    }
                    handleCancelOrderAction={(orderId, cancelReason) =>
                      cancelOrder({
                        id: orderId,
                        cancelRequest: { cancelReason: cancelReason },
                      })
                    }
                    handleSignatureFollowUpAction={(orderId) =>
                      sendVetSignatureReminder({ id: orderId })
                    }
                  />
                }
              />
            );
          })}
        </RenderByCondition>
      </Grid>
      <Grid item alignSelf={'center'} mt={4}>
        <CustomPagination
          currentPage={currentPage}
          handleChange={paginationOnClick}
          defaultPage={defaultPage}
          totalPages={totalPages}
        />
      </Grid>
      <BackdropLoader
        open={cancelOrderIsLoading || sendVetSignatureReminderIsLoading}
      />
    </Grid>
  );
};

export default AdminOrders;
