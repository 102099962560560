import { Grid, Stack, Typography } from '@mui/material';
import CustomButton from 'components/atoms/Button';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Search from 'components/icons/Search';
import {
  OrderActionsUsers,
  OrderCardDetail,
} from 'components/molecules/CardContent';
import { useLazyGetOrdersQuery } from 'api/orderForm';
import useCustomPagination from 'hooks/useCustomPagination';
import CustomPagination from 'components/molecules/CustomPagination';
import theme from 'theme';
import LoaderSkelton from 'components/atoms/LoaderSkelton';
import dayjs from 'dayjs';
import SearchBox from 'components/atoms/SearchBox';
import ReorderReminderPopUp from 'components/organisms/ReorderReminderPopUp';
import ReorderReminder from 'components/molecules/ReorderReminder';
import RenderByCondition from 'components/atoms/RenderByCondition';
import { useCreateReminderOrderDateMutation } from 'api/dashboard';
import useToast from 'hooks/useToast';
import { StyledBox } from './styles';
import { useLocation } from 'react-router-dom';
import { useCustomNavigationLogic } from 'hooks/useCustomNavigationLogic';
import { useSelector } from 'react-redux';
import { getUserRole } from 'store/features/auth/index.selector';
import {
  defaultDashboardPageSize,
  PAGES,
  DASHBOARD_INITIAL_PAGE,
  ORDER_STATUSES,
} from 'constants/index';
import { formatDate, toSpaceSeparatedNumber } from 'utils';

const RenderOrder = ({
  order,
  orderSerialStepStatuses,
  reminderDateValue,
  reminderModalButtonOnClick,
}) => {
  const formattedSubmissionDate = order?.creationDate
    ? formatDate(order?.creationDate)
    : null;

  const formattedConfirmationDate = order?.confirmationDate
    ? formatDate(order?.confirmationDate)
    : null;

  const formattedShippingDate = order?.shippingDate
    ? formatDate(order?.shippingDate)
    : null;

  const numberOfDoses = toSpaceSeparatedNumber(
    order?.numberOfDoses
  ).toLocaleString();

  const orderSerials = order?.orderSerials
    ?.map((orderSerial) => {
      let lastNonNullStepIndex = -1; // Index of the last step with a non-null stepDate

      orderSerial?.orderSerialSteps?.forEach((serialStep, index) => {
        if (serialStep?.stepDate !== null) {
          lastNonNullStepIndex = index;
        }
      });

      return {
        ...orderSerial,
        serialNumber: orderSerial?.serialNumber,
        steps: orderSerial?.orderSerialSteps?.map((serialStep, index) => {
          const stepData = orderSerialStepStatuses?.find(
            (item) => item?.id === serialStep?.orderSerialStepStatusId
          );

          const formattedDate = serialStep?.stepDate
            ? formatDate(serialStep.stepDate)
            : null;

          return {
            ...serialStep,
            name: stepData?.name,
            date: formattedDate,
            description: stepData?.description,
            color: stepData?.color,
            current: index === lastNonNullStepIndex,
          };
        }),
      };
    })
    .filter((item) => item);

  return (
    <OrderCardDetail
      data={order}
      key={order?.id}
      orderSerialStepStatuses={orderSerialStepStatuses}
      orderSerials={orderSerials}
      formattedSubmissionDate={formattedSubmissionDate}
      formattedConfirmationDate={formattedConfirmationDate}
      formattedShippingDate={formattedShippingDate}
      numberOfDoses={numberOfDoses}
      actionButtons={
        <Grid container flexDirection={'row'} spacing={2} alignItems={'center'}>
          <RenderByCondition show={[reminderDateValue !== null]}>
            <ReorderReminder
              value={reminderDateValue}
              editIconOnClick={() => reminderModalButtonOnClick('edit')}
            />
          </RenderByCondition>
          <Grid item>
            <OrderActionsUsers
              orderId={order?.id}
              customerId={order?.customer?.id}
              isRequestLetterGenerationEnabled={
                order?.isRequestLetterGenerationEnabled
              }
              reminderModalButtonOnClick={() =>
                reminderModalButtonOnClick('create')
              }
              showReminderButton={reminderDateValue === null}
              actionsDisabled={
                order?.orderStatus?.name?.toLowerCase?.() ===
                ORDER_STATUSES.CANCELLED?.toLowerCase?.()
              }
            />
          </Grid>
        </Grid>
      }
    />
  );
};

const initialReminderData = {
  reminderModalIsOpen: false,
  reminderDateValue: null,
  orderId: null,
  mode: '',
};

const Orders = () => {
  const userRole = useSelector(getUserRole);
  const { getParamsValues, paramsValues, setParams }: any =
    useCustomNavigationLogic({
      queryParams: ['searchKey', 'page', 'pageSize'],
      originLocation: PAGES[userRole].DASHBOARD,
    });
  const lc = useLocation();
  const [refetch, { data, isFetching, isLoading }] = useLazyGetOrdersQuery();
  const [
    createReminder,
    { isLoading: createReminderIsLoading, isSuccess: createReminderIsSuccess },
  ] = useCreateReminderOrderDateMutation();
  const { currentPage, handleChange, defaultPage, totalPages } =
    useCustomPagination({ initialPage: 1, totalPages: data?.totalPages });
  const [saveIsLoading, setSaveIsLoading] = useState(false);
  const [removeIsLoading, setRemoveIsLoading] = useState(false);
  const { toast } = useToast();
  const [inputValue, setInputValue] = useState('');
  const { t } = useTranslation();
  const [reminderData, setReminderData] =
    React.useState<any>(initialReminderData);

  useEffect(() => {
    getParamsValues(lc?.pathname);
  }, [lc]);

  useEffect(() => {
    setInputValue(paramsValues?.searchKey || '');

    const pageNumber = parseInt(paramsValues?.page) || DASHBOARD_INITIAL_PAGE;

    handleChange({} as any, pageNumber || DASHBOARD_INITIAL_PAGE);

    refetch({
      pageNumber: pageNumber || DASHBOARD_INITIAL_PAGE,
      searchKey: paramsValues?.searchKey || '',
      pageSize: parseInt(paramsValues?.pageSize) || defaultDashboardPageSize,
    });
  }, [paramsValues]);

  const handleSearchChange = (event) => {
    const value = event?.target?.value;

    setInputValue(value);

    if (value === '') {
      setParams({
        searchKey: value,
        page: DASHBOARD_INITIAL_PAGE,
        pageSize: defaultDashboardPageSize,
      });
    }
  };

  useEffect(() => {
    if (createReminderIsSuccess && !createReminderIsLoading) {
      if (saveIsLoading)
        toast.success(t('dashboard.reorderReminderSuccessMessage'), {});
      else if (removeIsLoading)
        toast.success(t('dashboard.reorderDeleteReminderSuccessMessage'), {});
      setSaveIsLoading(false);
      setRemoveIsLoading(false);
      setReminderData(initialReminderData);
    }
  }, [createReminderIsSuccess, createReminderIsLoading]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setParams({
        searchKey: inputValue,
        page: DASHBOARD_INITIAL_PAGE,
        pageSize: defaultDashboardPageSize,
      });
      refetch({ pageNumber: DASHBOARD_INITIAL_PAGE, searchKey: inputValue });
    }
  };

  const onSearchClick = () => {
    setParams({
      searchKey: inputValue,
      page: DASHBOARD_INITIAL_PAGE,
      pageSize: defaultDashboardPageSize,
    });
    refetch({ pageNumber: DASHBOARD_INITIAL_PAGE, searchKey: inputValue });
  };

  const paginationOnClick = (event, value) => {
    handleChange(event, value);
    setParams({
      searchKey: inputValue || '',
      page: value,
      pageSize: defaultDashboardPageSize,
    });
    refetch({
      pageNumber: value,
      searchKey: inputValue || '',
      pageSize: defaultDashboardPageSize,
    });
  };

  const saveReminderData = () => {
    setSaveIsLoading(true);
    createReminder({
      id: reminderData.orderId,
      date: reminderData.reminderDateValue,
    });
  };

  const resetDate = (reason) => {
    if (reason === 'cancel') {
      setReminderData(initialReminderData);
    } else {
      setRemoveIsLoading(true);
      createReminder({
        id: reminderData.orderId,
        date: '',
      });
    }
  };

  const orders: any = data?.items;
  const orderSerialStepStatuses = data?.orderSerialStepStatuses;

  return (
    <Grid
      container
      direction="column"
      marginX={{ xs: 'auto' }}
      width={{ xs: '98%', md: '100%' }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        spacing={1}
        marginY={4}
      >
        <Grid item xs>
          <SearchBox
            size="medium"
            placeholder={t('global.searchPlaceHolder')}
            startIcon={<Search />}
            onChange={handleSearchChange}
            onKeyDown={handleKeyPress}
            value={inputValue}
          />
        </Grid>
        <Grid item>
          <CustomButton
            variant="outlined"
            size="medium"
            onClick={onSearchClick}
          >
            {t('global.search')}
          </CustomButton>
        </Grid>
      </Grid>
      <Grid item>
        <RenderByCondition show={[Boolean(isFetching || isLoading)]}>
          <Stack spacing={2} width="100%">
            <LoaderSkelton />
            <br />
            <LoaderSkelton />
          </Stack>
        </RenderByCondition>
        <RenderByCondition show={[orders?.length === 0]}>
          <StyledBox>
            <Typography
              variant="body1"
              color={theme.palette.primary[800]}
              sx={{ my: 1 }}
            >
              {t('errorMessages.notFoundSearch')}
            </Typography>
          </StyledBox>
        </RenderByCondition>
        <RenderByCondition show={[orders?.length > 0, !isFetching]}>
          {orders?.map((order: any) => {
            return (
              <RenderOrder
                key={order?.id}
                order={order}
                orderSerialStepStatuses={orderSerialStepStatuses}
                reminderModalButtonOnClick={(mode = '') => {
                  setReminderData((state) => ({
                    ...state,
                    reminderDateValue:
                      mode === 'edit' ? dayjs(order?.reorderDate) : null,
                    reminderModalIsOpen: true,
                    orderId: order?.id,
                    mode: mode,
                  }));
                }}
                reminderDateValue={order?.reorderDate}
              />
            );
          })}
        </RenderByCondition>
      </Grid>
      <ReorderReminderPopUp
        setDate={(date) =>
          setReminderData((state) => ({ ...state, reminderDateValue: date }))
        }
        date={reminderData.reminderDateValue}
        resetDate={resetDate}
        saveDate={saveReminderData}
        isOpen={reminderData.reminderModalIsOpen}
        setOpen={(isOpen) =>
          setReminderData((state) => ({
            ...state,
            reminderModalIsOpen: isOpen,
          }))
        }
        saveIsLoading={createReminderIsLoading && saveIsLoading}
        removeIsLoading={createReminderIsLoading && removeIsLoading}
        reminderMode={reminderData.mode}
        orderId={reminderData.orderId}
        minDate={dayjs().add(84, 'day')}
      />
      <Grid item alignSelf={'center'} mt={4}>
        <CustomPagination
          currentPage={currentPage}
          handleChange={paginationOnClick}
          defaultPage={defaultPage}
          totalPages={totalPages}
        />
      </Grid>
    </Grid>
  );
};

export default Orders;
