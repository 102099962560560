import { FormName } from 'pages/User/ReOrderForm/types';
import { useEffect, useState } from 'react';
import { setFieldValue } from 'store/features/orderForm/index.slice';
import { getEnvironmentSettings, getFieldVisibility } from 'utils';
import { useSelector } from 'react-redux';
import {
  getFarmData,
  getFormConfig,
  getOrderFormMode,
} from 'store/features/orderForm/index.selectors';
import { ModeState } from 'store/features/orderForm/index.type';
import { getUserAccount } from 'store/features/auth/index.selector';
import {
  FormStepKeys,
  AdditionalInstructionsInfoKeys,
  InfectionInfoKeys,
  DosingAndFillingInfoKeys,
  FarmInfoKeys,
  ISOLATE_DEROGATION_INFO,
  USER_ROLE,
} from 'constants/index';
import { useTranslation } from 'react-i18next';
import {
  setNewOrderAddresses,
  setProximityAddressesOrder,
  setSelectedFarmsRows,
  setSelectedShippingRowsForState,
  setStatesDataOrder,
} from 'store/features/addressesManager/index.slice';
import {
  useLazyGetAuthorizedIsolatesQuery,
  useLazyGetIsolateByIdQuery,
} from 'api/orderForm';

const {
  ROUTE_OF_ADMINISTRATION_VISIBILITY,
  SHIPPING_ADDRESS_SELECT_VISIBILITY,
  ISOLATE_DEROGATION_COLUMN_VISIBILITY,
} = getFieldVisibility();

const { REORDER_RESET_NUMBER_OF_BOTTLES, REORDER_RESET_BOTTLES_VOLUMES } =
  getEnvironmentSettings();

const initializeFields = (dispatch, fieldConfig) => {
  fieldConfig?.forEach((field) => {
    dispatch(
      setFieldValue({
        propPath: field.propPath,
        value: field.data,
        formName: field.formName as FormName,
      })
    );
  });
};

const getVets = (data) => {
  const allVets = data?.customers?.reduce((accumulator, customer) => {
    customer?.vets?.forEach((vet) => {
      if (!accumulator.has(vet.id)) {
        accumulator.set(vet.id, vet);
      }
    });
    return accumulator;
  }, new Map());

  return allVets ? [...allVets.values()] : [];
};

const setFarmAddressesSelectedRows = (
  selectedAddresses,
  addresses,
  dispatch,
  setValueFn
) => {
  const currentFarms = selectedAddresses || [];
  const ids: number[] = [];

  currentFarms?.forEach((address) => {
    ids.push(address?.id);
  });

  const farmsSet = new Set(ids);

  dispatch(setValueFn(Array.from(farmsSet)));
};

const setShippingAddressesSelectedRows = (
  selectedAddresses,
  selectedFarmAddresses,
  addresses,
  dispatch,
  setValueFn
) => {
  const shippingSitesList = addresses || [];
  const currentShippingSites = selectedAddresses || [];
  const stateIds = currentShippingSites?.map((site) => site?.stateId);
  const uniqueStateIds = Array.from(new Set(stateIds));

  uniqueStateIds?.forEach((currentStateId) => {
    const ids: number[] = [];
    currentShippingSites?.forEach((valueItem) => {
      const address = shippingSitesList?.find(
        (dataItem) =>
          dataItem?.id === valueItem?.addressId &&
          valueItem?.stateId == currentStateId
      );
      // Adding 1 to index to start from 1 instead of 0
      ids.push(address?.id);
    });
    const farmsSet = new Set(ids);

    dispatch(
      setValueFn({
        stateId: currentStateId,
        rows: Array.from(farmsSet),
      })
    );

    const uniqueStates = [
      ...new Set(
        selectedFarmAddresses?.map((item) => item?.farm?.address?.state?.id)
      ),
    ];

    const uniqueStateObjects: any = uniqueStates.map((stateId) => ({
      id: stateId,
      name: selectedFarmAddresses?.find(
        (item) => item?.farm?.address?.state?.id === stateId
      )?.farm?.address?.state?.name,
      variant: 'body1',
    }));

    dispatch(setStatesDataOrder(uniqueStateObjects));
  });
};

const calculateTotal = (bottles, volumePerDose) => {
  let totalVolume = 0;
  let totalCount = 0;

  bottles?.forEach((bottle) => {
    if (bottle?.count > 0) {
      totalVolume += bottle?.count * bottle?.volume;
      totalCount += bottle?.count;
    }
  });

  const totalDoses = totalVolume / volumePerDose;

  return { totalCount, totalVolume, totalDoses };
};

const useInitializeFormData = (dispatch, config, data, isLoading, error) => {
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const userAccount = useSelector(getUserAccount);
  const orderFormMode = useSelector(getOrderFormMode);
  const apiConfiguration = useSelector(getFormConfig);
  const [getIsolate] = useLazyGetIsolateByIdQuery();
  const [getAuthorizedIsolates] = useLazyGetAuthorizedIsolatesQuery();
  const { t } = useTranslation();

  const isNonAdjacentRequired =
    useSelector(getFarmData)?.nonAdjacentRequired?.value;

  const IS_EDIT_MODE = orderFormMode == ModeState.EDIT;
  const IS_REORDER_MODE = orderFormMode == ModeState.REORDER;

  useEffect(() => {
    (async function () {
      if (!isLoading && data && config && userAccount?.id) {
        if (IS_EDIT_MODE) {
          setIsFormDisabled(
            !(
              data?.isDraft === true && userAccount?.id === data?.lockedByUserId
            )
          );
        } else {
          setIsFormDisabled(false);
        }
        const vetsOptions = getVets(apiConfiguration);

        const customerData = apiConfiguration?.customers?.find(
          (customer) => customer?.id === data?.customer?.id
        );

        let vetValue;
        if (userAccount?.role === USER_ROLE.VET) {
          if (userAccount?.id === data?.vet?.id) {
            vetValue = {
              ...data?.vet,
              label: data?.vet?.userName,
              value: data?.vet?.userName,
            };
          } else {
            const dataVet = vetsOptions?.find(
              (vet) => vet?.id === userAccount?.id
            );
            vetValue = {
              ...dataVet,
              label: dataVet?.userName,
              value: dataVet?.userName,
            };
          }
        } else {
          vetValue = {
            ...data?.vet,
            label: data?.vet?.userName,
            value: data?.vet?.userName,
          };
        }
        const customerValue = {
          ...customerData,

          label: customerData?.name,
          value: customerData?.name,
        };
        const customersOptions = apiConfiguration?.customers?.filter(
          (customer) => customer?.vets?.find((vet) => vet?.id === vetValue?.id)
        );

        let farmValue = [];

        if (isNonAdjacentRequired) {
          farmValue = data?.nonAdjacentFarms?.map((farm) => ({
            ...farm,
            id: farm?.farmId,
          }));
          getIsolate({ customerId: customerValue?.id, farmId: '' });
        } else {
          farmValue = [
            { ...data?.farm, id: data?.farm?.id, farmId: data?.farm?.id },
          ];
          getIsolate({ customerId: customerValue?.id, farmId: data?.farm?.id });
        }

        const farmsOptions = apiConfiguration?.customers?.find(
          (customer) => customer?.id === customerValue?.id
        )?.farms;

        setFarmAddressesSelectedRows(
          farmValue,
          farmsOptions,
          dispatch,
          setSelectedFarmsRows
        );

        const shippingAddressesValue = SHIPPING_ADDRESS_SELECT_VISIBILITY
          ? [
              {
                ...data?.orderShippingAddresses?.[0],
                id: data?.orderShippingAddresses?.[0]?.address?.id,
                label: data?.orderShippingAddresses?.[0]?.address?.name,
                value: data?.orderShippingAddresses?.[0]?.address?.name,
              },
            ]
          : data?.orderShippingAddresses;

        const shippingAddressesOptions = apiConfiguration?.customers?.find(
          (customer) => customer?.id === customerValue?.id
        )?.shippingAddresses;

        if (shippingAddressesValue?.length === 0 || !shippingAddressesValue) {
          const statesData = farmValue?.map((farm) => ({
            id: farm?.farm?.address?.state?.id,
            name: farm?.farm?.address?.state?.name,
            code: farm?.farm?.address?.state?.code,
            variant: 'body1',
          }));

          const proximityAddresses = farmValue?.map((farm) => ({
            orderId: farm?.orderId,
            farmId: farm?.farmId,
            addressId: farm?.farm?.addressId,
            stateId: farm?.farm?.address?.state?.id,
          }));

          dispatch(setProximityAddressesOrder(proximityAddresses));
          dispatch(setStatesDataOrder(statesData));
        }

        setShippingAddressesSelectedRows(
          shippingAddressesValue,
          farmValue,
          shippingAddressesOptions,
          dispatch,
          setSelectedShippingRowsForState
        );

        const billingAddressesValue = {
          ...data?.customer,
          label: data?.customer?.name,
          value: data?.customer?.name,
        };

        const billingAddressesOptions = apiConfiguration?.customers?.filter(
          (customer) => customer?.vets?.find((vet) => vet?.id === vetValue?.id)
        );

        const animalGroupOptions = apiConfiguration?.animalGroups;

        const animalGroupValue = animalGroupOptions
          ? animalGroupOptions
              .filter((animalGroup) => animalGroup.id === data.animalGroup.id)
              .map((animalGroup) => {
                return {
                  ...animalGroup,
                  label: animalGroup.name,
                  value: animalGroup.id,
                };
              })[0]
          : {};

        const animalSpecieValue = {
          ...data?.animalSpecies,
          label: data?.animalSpecies?.name,
          value: data?.animalSpecies?.id,
        };

        const animalSpecieOptions = animalGroupOptions?.find(
          (animal) => animal.id === animalGroupValue?.id
        )?.animalSpecies;

        const isolateNeedsDerogation = (data, isolateId: any) =>
          !data?.find((ai) => ai?.isolateId == isolateId);

        let isolatesValue = [];

        if (ISOLATE_DEROGATION_COLUMN_VISIBILITY) {
          const res = await getAuthorizedIsolates({
            customerId: customerValue?.id,
            farmId: farmValue?.[0]?.farmId,
            animalSpecieId: animalSpecieValue?.id,
          }).unwrap();

          isolatesValue = data?.orderIsolates?.map((orderIsolate) => ({
            ...orderIsolate.isolate,
            isBoosted: orderIsolate?.isBoosted,
            label: orderIsolate?.isolate?.name,
            value: orderIsolate?.isolate?.name,
            styleInfo: isolateNeedsDerogation(res, orderIsolate?.isolate?.id)
              ? {
                  ...ISOLATE_DEROGATION_INFO,
                  name: ISOLATE_DEROGATION_INFO.getName(t),
                }
              : null,
          }));
        } else {
          isolatesValue = data?.orderIsolates?.map((orderIsolate) => ({
            ...orderIsolate.isolate,
            isBoosted: orderIsolate?.isBoosted,
            label: orderIsolate?.isolate?.name,
            value: orderIsolate?.isolate?.name,
          }));
        }

        const productValue = {
          ...data?.product,
          value: data?.product?.name,
          label: data?.product?.name,
        };

        const productOptions = animalSpecieOptions?.find(
          (specie) => specie.id === animalSpecieValue.id
        )?.products;

        const adjuvantValue = {
          ...data?.adjuvant,
          label: data?.adjuvant?.name,
          value: data?.adjuvant?.name,
        };

        const adjuvantOptions =
          productOptions?.find((product) => product.id === productValue.id)
            ?.adjuvants || [];

        const routeOfAdministrationValue = {
          ...data?.administrationRoute,
          label: data?.administrationRoute?.name,
          value: data?.administrationRoute?.name,
        };

        const routeOfAdministrationOptions = adjuvantOptions?.find(
          (ad) => ad.id === adjuvantValue.id
        )?.administrationRoutes;

        const doseValue = data?.dose
          ? {
              label: `${data.dose.volume} ml`,
              value: data.dose.volume,
              ...data.dose,
            }
          : null;

        const doseOptions = ROUTE_OF_ADMINISTRATION_VISIBILITY
          ? routeOfAdministrationOptions?.find(
              (route) => route?.id === routeOfAdministrationValue?.id
            )?.doses
          : adjuvantOptions?.find((ad) => ad?.id === adjuvantValue?.id)?.doses;

        const bottleOptions = doseOptions?.find(
          (dose) => dose.id === doseValue?.id
        )?.bottles;

        const allBottles =
          bottleOptions?.map((bottle) => ({
            ...bottle,
            id: bottle?.id,
            volume: bottle?.volume,
            count: 0,
            price: 0,
          })) || [];

        const bottleValue =
          data?.orderBottles && data?.orderBottles?.length !== 0
            ? data?.orderBottles?.map((bottle) => ({
                ...bottle,
                id: bottle?.bottleId,
                volume: bottle?.bottle?.volume,
                count:
                  REORDER_RESET_NUMBER_OF_BOTTLES && IS_REORDER_MODE
                    ? 0
                    : bottle?.bottleCount,
                price: bottle?.bottlePrice,
              }))
            : null;

        const { totalCount, totalVolume, totalDoses } = calculateTotal(
          bottleValue,
          doseValue?.volume
        );

        const animalCount = data?.animalCount;

        const colorValue = {
          ...data?.capColor,
          label: data?.capColor?.name,
          value: data?.capColor?.hexCode,
        };

        const colorOptions = apiConfiguration?.capColors;

        const bottleTag = data?.bottleTag;
        const internalReference = data?.internalReference;
        const vaccinationSchedule = data?.vaccinationSchedule;
        const clinicalSign = data?.clinicalSign;
        const requestLetterComment = data?.requestLetterComment;
        const comment = data?.comment;
        const monthlyBottleUsage = data?.monthlyBottleUsage || 0;

        const fieldConfig = [
          {
            propPath: `${FarmInfoKeys.VETS}.value`,
            data: vetValue,
            formName: FormStepKeys.FARM_INFO,
          },
          {
            propPath: `${FarmInfoKeys.VETS}.data`,
            data: vetsOptions,
            formName: FormStepKeys.FARM_INFO,
          },
          {
            propPath: `${FarmInfoKeys.CUSTOMER}.value`,
            data: customerValue,
            formName: FormStepKeys.FARM_INFO,
          },
          {
            propPath: `${FarmInfoKeys.CUSTOMER}.data`,
            data: customersOptions,
            formName: FormStepKeys.FARM_INFO,
          },
          {
            propPath: `${FarmInfoKeys.BILLING_ADDRESSES}.value`,
            data: billingAddressesValue,
            formName: FormStepKeys.FARM_INFO,
          },
          {
            propPath: `${FarmInfoKeys.BILLING_ADDRESSES}.data`,
            data: billingAddressesOptions,
            formName: FormStepKeys.FARM_INFO,
          },
          {
            propPath: `${FarmInfoKeys.FARM_ADDRESSES}.value`,
            data: farmValue,
            formName: FormStepKeys.FARM_INFO,
          },
          {
            propPath: `${FarmInfoKeys.FARM_ADDRESSES}.data`,
            data: farmsOptions,
            formName: FormStepKeys.FARM_INFO,
          },
          {
            propPath: `${FarmInfoKeys.FARM_ADDRESSES}.selectCount`,
            data: farmValue?.length,
            formName: FormStepKeys.FARM_INFO,
          },
          {
            propPath: `${FarmInfoKeys.FARM_ADDRESSES}.totalCount`,
            data: farmsOptions?.length,
            formName: FormStepKeys.FARM_INFO,
          },
          {
            propPath: `${FarmInfoKeys.SHIPPING_SITES}.value`,
            data: shippingAddressesValue,
            formName: FormStepKeys.FARM_INFO,
          },
          {
            propPath: `${FarmInfoKeys.SHIPPING_SITES}.data`,
            data: shippingAddressesOptions,
            formName: FormStepKeys.FARM_INFO,
          },
          {
            propPath: `${FarmInfoKeys.SHIPPING_SITES}.selectCount`,
            data: shippingAddressesValue?.length,
            formName: FormStepKeys.FARM_INFO,
          },
          {
            propPath: `${FarmInfoKeys.SHIPPING_SITES}.totalCount`,
            data: shippingAddressesOptions?.length,
            formName: FormStepKeys.FARM_INFO,
          },
          {
            propPath: `${InfectionInfoKeys.ISOLATES}.value`,
            data: isolatesValue,
            formName: FormStepKeys.INFECTION_INFO,
          },
          {
            propPath: `${InfectionInfoKeys.ANIMAL_GROUPS}.value`,
            data: animalGroupValue,
            formName: FormStepKeys.INFECTION_INFO,
          },
          {
            propPath: `${InfectionInfoKeys.ANIMAL_GROUPS}.data`,
            data: animalGroupOptions,
            formName: FormStepKeys.INFECTION_INFO,
          },
          {
            propPath: `${InfectionInfoKeys.ANIMAL_GROUPS}.value`,
            data: animalGroupValue,
            formName: FormStepKeys.INFECTION_INFO,
          },
          {
            propPath: `${InfectionInfoKeys.ANIMAL_GROUPS}.data`,
            data: animalGroupOptions,
            formName: FormStepKeys.INFECTION_INFO,
          },
          {
            propPath: `${InfectionInfoKeys.ANIMAL_SPECIES}.value`,
            data: animalSpecieValue,
            formName: FormStepKeys.INFECTION_INFO,
          },
          {
            propPath: `${InfectionInfoKeys.ANIMAL_SPECIES}.data`,
            data: animalSpecieOptions,
            formName: FormStepKeys.INFECTION_INFO,
          },
          {
            propPath: `${InfectionInfoKeys.PRODUCTS}.value`,
            data: productValue,
            formName: FormStepKeys.INFECTION_INFO,
          },
          {
            propPath: `${InfectionInfoKeys.PRODUCTS}.data`,
            data: productOptions,
            formName: FormStepKeys.INFECTION_INFO,
          },
          {
            propPath: `${InfectionInfoKeys.ADJUVANTS}.value`,
            data: adjuvantValue,
            formName: FormStepKeys.INFECTION_INFO,
          },
          {
            propPath: `${InfectionInfoKeys.ADJUVANTS}.data`,
            data: adjuvantOptions,
            formName: FormStepKeys.INFECTION_INFO,
          },
          {
            propPath: `${InfectionInfoKeys.ADMINISTRATION_ROUTES}.value`,
            data: routeOfAdministrationValue,
            formName: FormStepKeys.INFECTION_INFO,
          },
          {
            propPath: `${InfectionInfoKeys.ADMINISTRATION_ROUTES}.data`,
            data: routeOfAdministrationOptions,
            formName: FormStepKeys.INFECTION_INFO,
          },
          {
            propPath: `${InfectionInfoKeys.DOSES}.value`,
            data: doseValue,
            formName: FormStepKeys.INFECTION_INFO,
          },
          {
            propPath: `${InfectionInfoKeys.DOSES}.data`,
            data: doseOptions,
            formName: FormStepKeys.INFECTION_INFO,
          },
          {
            propPath: `${DosingAndFillingInfoKeys.BOTTLES}.value`,
            data:
              REORDER_RESET_BOTTLES_VOLUMES && IS_REORDER_MODE
                ? allBottles
                : bottleValue,
            formName: FormStepKeys.DOSING_AND_FILLING_INFO,
          },
          {
            propPath: `${DosingAndFillingInfoKeys.BOTTLES}.data`,
            data: bottleOptions,
            formName: FormStepKeys.DOSING_AND_FILLING_INFO,
          },
          {
            propPath: `${DosingAndFillingInfoKeys.BOTTLES}.totalVolume`,
            data: totalVolume || 0,
            formName: FormStepKeys.DOSING_AND_FILLING_INFO,
          },
          {
            propPath: `${DosingAndFillingInfoKeys.BOTTLES}.totalCount`,
            data: totalCount || 0,
            formName: FormStepKeys.DOSING_AND_FILLING_INFO,
          },
          {
            propPath: `${DosingAndFillingInfoKeys.BOTTLES}.totalDoses`,
            data: totalDoses || 0,
            formName: FormStepKeys.DOSING_AND_FILLING_INFO,
          },
          {
            propPath: `${AdditionalInstructionsInfoKeys.COLORS}.value`,
            data: colorValue,
            formName: FormStepKeys.ADDITIONAL_INSTRUCTIONS_INFO,
          },
          {
            propPath: `${AdditionalInstructionsInfoKeys.COLORS}.data`,
            data: colorOptions,
            formName: FormStepKeys.ADDITIONAL_INSTRUCTIONS_INFO,
          },
          {
            propPath: `${AdditionalInstructionsInfoKeys.BOTTLE_TAG}.value`,
            data: bottleTag,
            formName: FormStepKeys.ADDITIONAL_INSTRUCTIONS_INFO,
          },
          {
            propPath: `${AdditionalInstructionsInfoKeys.INTERNAL_REFERENCE}.value`,
            data: internalReference,
            formName: FormStepKeys.ADDITIONAL_INSTRUCTIONS_INFO,
          },
          {
            propPath: `${AdditionalInstructionsInfoKeys.VACCINATION_SCHEDULE}.value`,
            data: vaccinationSchedule,
            formName: FormStepKeys.ADDITIONAL_INSTRUCTIONS_INFO,
          },
          {
            propPath: `${AdditionalInstructionsInfoKeys.CLINICAL_SIGNS}.value`,
            data: clinicalSign,
            formName: FormStepKeys.ADDITIONAL_INSTRUCTIONS_INFO,
          },
          {
            propPath: `${AdditionalInstructionsInfoKeys.REQUEST_LETTER_COMMENT}.value`,
            data: requestLetterComment,
            formName: FormStepKeys.ADDITIONAL_INSTRUCTIONS_INFO,
          },

          {
            propPath: `${AdditionalInstructionsInfoKeys.ADDITIONAL_COMMENT}.value`,
            data: comment,
            formName: FormStepKeys.ADDITIONAL_INSTRUCTIONS_INFO,
          },
          {
            propPath: `${DosingAndFillingInfoKeys.BOTTLE_USAGE}.value`,
            data: monthlyBottleUsage,
            formName: FormStepKeys.DOSING_AND_FILLING_INFO,
          },
          {
            propPath: `${InfectionInfoKeys.NUM_OF_ANIMAL}.value`,
            data: animalCount,
            formName: FormStepKeys.INFECTION_INFO,
          },
        ];

        dispatch(setNewOrderAddresses(shippingAddressesValue));

        // Initialize other fields using the helper function
        initializeFields(dispatch, fieldConfig);
      }
    })();
  }, [dispatch, config, data, isLoading, error, userAccount?.id]);

  return {
    isFormDisabled,
    toggleFormDisabled: () => setIsFormDisabled(!isFormDisabled),
  };
};

export default useInitializeFormData;
